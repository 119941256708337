<template>
  <div class="Subscription">
    <Spacer num="1" />
    <UserAvatar :user="subscription.creator" size="120px" mauto @click="openCreator" />
    <Spacer num="1" />
    <p strong mauto tcenter @click="openCreator" active="button">@{{ subscription.creator.user }}</p>

    <Spacer num="1" />
    <LargeButton icon="false" :label="$locale['subscription_date']" :rightDesc="`<small>${date_locale(subscription.date)}</small>`" />
    <LargeButton
      icon="false"
      :label="$locale['subscription_status']"
      :rightDesc="`<small>${subscription.isActive ? $locale['active'] : $locale['expired']}</small>`"
    />
    <LargeButton icon="false" :label="$locale['subscription_period']" :rightDesc="`<small>${$locale[subscription.period]}</small>`" />

    <Spacer num="1" style="background-color: var(--body-bg)" />

    <LargeButton icon="false" :label="$locale['cancel_subscription']" dcolor @click="cancelSubscription" v-if="subscription.isActive || $isDev" />

    <div class="RenewSubscriptionBlock" dpadding v-if="!subscription.isActive">
      <LargeButton icon="false" :label="$locale['renew_subscription']" class="primary-button" @click="subscribe(subscription)" />
    </div>

    <Spacer v-if="$isMobile" num="3" />
  </div>
</template>

<script>
export default {
  props: ["subscription", "modal"],
  methods: {
    subscribe: function(subscription) {
      this.modal.close(async () => {
        this.BuySubscription({ ...subscription, ...subscription.creator });
      });
    },
    cancelSubscription: function() {
      this.modal.close(async () => {
        this.view({
          title: this.$locale["cancel_subscription"],
          class: "menu bottom",
          component: () => import("@/components/buy/CancelSubscription.vue"),
          subscription: this.subscription,
          animation: "midBottomIn",
          closeOutside: true,
        });
      });
    },
    openCreator: function() {
      this.modal.close(async () => {
        this.$router.push(`/${this.subscription.creator.user}`).catch((err) => {});
      });
    },
  },
};
</script>
